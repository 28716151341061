const foodItems = [
    {
        id: 1,
        name: "pizza",
        description: "Add some description of dish",
        items: [" Small ", " Large ", " Extra-Large "],
    },
    {
        id: 2,
        name: "salad",
        description: "Add some description of dish",
        items: [" half ", " full ", " quater "],
    },
    {
        id: 3,
        name: "sushi",
        description: "Add some description of dish",
        items: [" half ", " full ", " quater ", "medium"],
    },

    {
        id: 11,
        name: "ice cream",
        description: "Add some description of dish",
        items: [" half ", " full ", " quater "],
    },
    {
        id: 12,
        name: "cake",
        description: "Add some description of dish",
        items: [" half ", " full ", " quater "],
    },
    {
        id: 13,
        name: "brownie",
        description: "Add some description of dish",
        items: [" half ", " full ", " quater ", "medium"],
    },

    {
        id: 21,
        name: "nomal paneer",
        description: "Add some description of dish",
        items: [" half ", " full ", " quater "],
    },
    {
        id: 22,
        name: "sada paneer",
        description: "Add some description of dish",
        items: [" half ", " full ", " quater "],
    },
    {
        id: 23,
        name: "simple paneer",
        description: "Add some description of dish",
        items: [" half ", " full ", " quater ", "medium"],
    },
    {
        id: 31,
        name: "ice tea",
        description: "Add some description of dish",
        items: [" half ", " full ", " quater "],
    },
    {
        id: 32,
        name: "green tea",
        description: "Add some description of dish",
        items: [" half ", " full ", " quater "],
    },
    {
        id: 33,
        name: "coffee",
        description: "Add some description of dish",
        items: [" half ", " full ", " quater ", "medium"],
    },

];


export default foodItems;